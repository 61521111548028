import React from "react";
import "../styles/trainings.css";
import trainingSectionData from "../assets/training";
import { images } from "../images";
import { useTranslation } from "react-i18next";


export const Trainings = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'trainings' } );
  const handleContactClick = () => {
    window.location.href = 'mailto:connect@definenext.com';
  }
  return (
    <div className="training-main-container">
      <div className="title-container">
        <div className="title"> {t(`title`)} <img className="underline-img" src={images.trainingsUnderline} alt="" /> </div>
        {/* <img src={images.smallArrow} alt="arrow" /> */}
      </div>
      <div className="training-header">
        <div className="left">
          <div className="first">
            {t(`subTitle`)}
            <sapn className="green">
            {t(`subTitleGreen`)}
            </sapn>
          </div>
          <div className="second">
          {t(`headerParaPrefix`)} <span className="bold green">{t(`headerPara`)}</span> {t(`headerParaSuffix`)}
          </div>
        </div>
        {/* <div className="right">
          <img src={images.arrowBigImage} alt="arrow" />
        </div> */}
      </div>
      <hr />
      <div className="training-data">
        <div className="group">
        <div className="data-container left">
          <img
            src={trainingSectionData.trainingData2.image}
            alt="training image"
          />
        </div>
        <div className="data-container">
          <div className="data">
            <div className="data-title">
            {t(`dataHeading1`)}
            </div>
            <div className="data-para">
            {t(`dataPara1`)}
            </div>
          </div>
        </div>
        </div>

        <div className="group2">

        <div className="data-container">
          <div className="data">
            <div className="data-title">
            {t(`dataHeading2`)}
            </div>
            <div className="data-para">
            {t(`dataPara2`)}
            </div>
          </div>
        </div>

        <div className="data-container right">
          <img
          className="bottom-right-corner"
            src={trainingSectionData.trainingData1.image}
            alt="training image"
          />
        </div>
      </div>
        </div>
        

        
      <div className="last-section">
        <div className="note">{t(`footerNote`)}</div>
        {/* <Link to="Contact"smooth={true} duration={1200} offset={-30} > */}
        <button onClick={handleContactClick}>{t(`contact`)}</button>
        {/* </Link> */}
        <div className="sechedules">{t(`footerNote2`)}</div>
      </div>
    </div>
  );
};
