import React from "react";
import "../../styles/whoWeAre.css";
import { useTranslation } from "react-i18next";
import { Link,useNavigate } from "react-router-dom";

const Card = ({ cardData, isVisible, isMobile }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'whoWeAre' } );
  const navigate = useNavigate();
  if (!isVisible) {
    return null;
  }
  const goToReadMore = () => {
    navigate("/read-more",{state: {data:cardData}})
  }
  return (
    <div className={`card ${isMobile ? "mobile" : ""}`}>
      <div className="card-image">
        <img className="image" src={cardData.image} alt="img" />
      </div>
      <div className="card-title">{cardData.title}</div>
      <div onClick={goToReadMore} className="card-description">
        {cardData.description}
      </div>
    </div>
  );
};

export default Card;
