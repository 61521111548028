import React from "react";
import "../styles/landing_page.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";

export const LandingPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'landing' } );
  const parallax = useParallax({
    speed: -15,
  });
  return (
    <div className="landing-page">
      <div ref={parallax.ref} className="landing-page-container">
        <div className="defining">  {t(`title1`)}</div>
        <div className="digital-future">{t(`title2`)}</div>
        <div className="digital-transformation">
        {t(`subHeading`)}
        </div>
        <Link  to="/learn-more" className="learn-more">{t(`more`)}</Link>
        <div className="progress-bar"></div>
      </div>
    </div>
  );
};
