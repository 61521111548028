const learnMoreEn = {
    "learnMore" : [
      "AI based tools can assist the developers in building better software by automating code generation, providing autocompletion, and detecting bugs.",
      " Examples of such tools include OpenAI Codex, DeepCode, and Diffblue. AI tools also aid in code analysis, test generation, and error monitoring, as seen in CodeGuru, Kite, and Sentry.",
      " Additionally, tools like DeepTrace and SonarQube utilize AI to improve security and code quality. OverOps and GitSense use AI to analyze runtime data and provide insights for troubleshooting and optimization.",
      " Overall, these tools enhance productivity, code quality, and error detection throughout the software development process.",
    ],
    "title":"What is AI assisted digital transformations?"
  }
  
  export default learnMoreEn 
