const WhatWeDoEn = {
  "WhatWeDo": "What we do",
  "subHeading":"Transforming Your Vision into Reality - One step at a time.",
  "learnMore":"connect with us to learn more",
  "data":[
    {
      id: 1,
      heading: 'Digital transformations',
      content: 'We partner in adopting and integrating digital technologies and strategies across an organization to fundamentally change how it operates, delivers value, and interacts with stakeholders. Leveraging digital tools, data analytics, automation, cloud computing, artificial intelligence, and other emerging technologies to streamline operations, enhance customer experiences, optimize business processes, and drive innovation.'
  },
  ],
  "categories": {
    "Digital transformations": {
      id: 1,
      heading: 'Digital transformations',
      content: 'We partner in adopting and integrating digital technologies and strategies across an organization to fundamentally change how it operates, delivers value, and interacts with stakeholders. Leveraging digital tools, data analytics, automation, cloud computing, artificial intelligence, and other emerging technologies to streamline operations, enhance customer experiences, optimize business processes, and drive innovation.'
    },
    "Design services": {
      id: 2,
      heading: 'Design services',
      content: 'We take pride in creating awesome visual and interactive elements for digital platforms, such as websites, mobile applications, user interfaces, and other digital media. With the use of various design tools and techniques to conceptualize, layout, we produce visual assets that are aesthetically pleasing, user-friendly, and aligned with the intended purpose and brand identity.',
      subcontent: 'Digital design encompasses aspects such as user experience (UX) design, user interface (UI) design, graphic design, information architecture, and interactive prototyping. It focuses on creating engaging and intuitive digital experiences that effectively communicate information, enhance usability, and evoke positive user responses.'
    },
    "AI adoption": {
      id: 3,
      heading: 'AI adoption',
      content: 'We offer comprehensive AI and ML development services, including strategy development, data preparation, model development, integration, and ongoing support, to help organizations effectively implement AI and achieve business goals.'
    },
    "": {
      id: 4,
      heading: '',
      content: '',
      disabled: true
    },
    "Low Code": {
      id: 5,
      heading: 'Low Code',
      content: 'We also provide low-code development and consultancy as an alternative approach that simplifies software development by using visual interfaces and pre-built components, reducing the need for manual coding. It enables faster application development and empowers developers of various skill levels to create applications efficiently. With low-code development, organizations can streamline the development process, accelerate time-to-market, and foster greater agility in their software development initiatives.'
    },
    "DevOps and Devsecops": {
      id: 6,
      heading: 'DevOps and Devsecops',
      content: 'DevOps is built in our development practices. It involves the automation of software development, testing, deployment, and infrastructure management, allowing for faster and more frequent releases. By fostering a culture of collaboration, DevOps promotes shared responsibility, continuous integration, and continuous delivery, enabling organizations to deliver high-quality software products with shorter development cycles and quicker time-to-market.'
    },
    "Team augmentation": {
      id: 7,
      heading: 'Team augmentation',
      content: 'As a trusted and flexible partner we specialize in providing skilled professionals or dedicated teams to augment your existing workforce. Our pool of qualified and experienced colleauges can seamlessly integrate into your team and contribute to project development or specific tasks.'
    },
    "": {
      id: 8,
      heading: '',
      content: '',
      disabled: true
    },
    "Cloud native aka cloud first": {
      id: 9,
      heading: 'Cloud native aka cloud first',
      content: 'Cloud native development enables businesses to leverage scalable, on-demand computing resources, empowering rapid deployment and innovation of applications, services, and infrastructure, all while reducing maintenance and operational costs.',
    }
  }
}

export default WhatWeDoEn