import { insightsImages } from "../../../images";

const insightsGe = {
  insights: "Einblicke",
  "cardsText":[{
    img:insightsImages.modernize2,
    title: "Optimieren-1",
    details:
      "Infrastruktur als Code verstehen: Wie sie funktioniert und welche Vorteile sie bietet",
    date: "Artikel – 22. Mai 2023",
  },
   {
    img:insightsImages.optimize1,
    title: "Optimieren-2",
    details:
      "Infrastruktur als Code verstehen: Wie sie funktioniert und welche Vorteile sie bietet",
    date: "Artikel – 22. Mai 2023",
  },
  {
    img:insightsImages.modernize1,
    title: "Optimieren-3",
    details:
      "Infrastruktur als Code verstehen: Wie sie funktioniert und welche Vorteile sie bietet",
    date: "Artikel – 22. Mai 2023",
  },
 {
  img:insightsImages.optimize2,
    title: "Optimieren-4",
    details:
      "Infrastruktur als Code verstehen: Wie sie funktioniert und welche Vorteile sie bietet",
    date: "Artikel – 22. Mai 2023",
  }]
};

export default insightsGe;
