import React, { useEffect, useState } from "react";
import "../styles/header.css";
import { Link, scroller, Scroll, Events } from "react-scroll";
import { images } from "../images";
import NavbarModal from "./navbarModal/NavbarModal";
import { useTranslation } from "react-i18next";
import {
  Link as Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [hideApac, setHideApac] = useState(true);
  const [selectedLocale, setSelectedLocale] = useState("en");
  const { t, i18n } = useTranslation();
  const [hamburgerButtonClicked, setHamburgerButtonClicked] = useState(false);
  const [shrinkHeader, setShrinkHeader] = useState(false);
  const tabView = window.innerWidth <= 1232;

  const handleChangeLocale = (locale) => {
    i18n.changeLanguage(locale);
    setSelectedLocale(locale);
    setHideApac(false);
  };

  const toggleHandler = () => {
    setHamburgerButtonClicked(!hamburgerButtonClicked);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 150) {
        setShrinkHeader(true);
      } else {
        setShrinkHeader(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScrollToComponent(contentType, offset, duration) {
    // console.log(location.pathname , "Test")

    if (location.pathname != "/") {
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(contentType, {
          smooth: true,
          offset: offset,
          duration: duration,
          spy: true,
          activeClass: "active",
        });
      });
    } else {
      scroller.scrollTo(contentType, {
        smooth: true,
        offset: offset,
        duration: duration,
        spy: true,
        activeClass: "active",
      });
    }
  }

  const changeHamburger = (contentType, offset, duration) => {
    setHamburgerButtonClicked(false);
    handleScrollToComponent(contentType, offset, duration);
  };

  return (
    <div className="header-container">
      {
        <div
          className={
            hamburgerButtonClicked ? "mobile-nav-display" : "mobile-nav"
          }
        >
          {" "}
          <NavbarModal
            toggleHandler={toggleHandler}
            changeHamburger={changeHamburger}
          />{" "}
        </div>
      }
      <header className={shrinkHeader && !tabView ? "tiny-header" : ""}>
        <div className="logo">
          <Navigate to="/">
            <img
              className={
                shrinkHeader && !tabView ? "header-logo-shrink" : "header-logo"
              }
              src={images.logo}
              alt="company's logo"
              draggable={false}
            />
          </Navigate>
        </div>
        <ul
          className={
            shrinkHeader && !tabView ? "header-list-shrink" : "header-list"
          }
        >
          <li>
            {/* <Link
              className={shrinkHeader && !tabView ? "nav-item-shrink" : ""}
              to="WhoWeAre"
              spy={true}
              smooth={true}
              offset={-30}
              duration={500}
              activeClass="active"
            > */}
            <a
              className={shrinkHeader && !tabView ? "nav-item-shrink" : ""}
              onClick={() => handleScrollToComponent("WhoWeAre", -30, 500)}
              activeClass="active"
            >
              {t("whoWeAre.title")}
            </a>
            {/* </Link> */}
          </li>
          <li>
            <a
              onClick={() => handleScrollToComponent("WhatWeDO", -70, 700)}
              className={shrinkHeader && !tabView ? "nav-item-shrink" : ""}
              // to="WhatWeDO"
              // spy={true}
              // smooth={true}
              // offset={-70}
              // duration={700}
              activeClass="active"
            >
              {t("WhatWeDo.WhatWeDo")}
            </a>
          </li>
          <li>
            <a
              className={shrinkHeader && !tabView ? "nav-item-shrink" : ""}
              onClick={() => handleScrollToComponent("Industries", -50, 900)}
              // to="Industries"
              // spy={true}
              // smooth={true}
              // offset={-50}
              // duration={900}
              // activeClass="active"
            >
              {t("Industries.Industries")}
            </a>
          </li>
          {/* <li>
            <a
              className={shrinkHeader && !tabView ? "nav-item-shrink" : ""}
              onClick={() => handleScrollToComponent("Insights", -70, 1000)}
              // to="Insights"
              // spy={true}
              // smooth={true}
              // offset={-70}
              // duration={1000}
              // activeClass="active"
            >
              {t("insights.insights")}
            </a>
          </li> */}
          <li>
            <a
              className={shrinkHeader && !tabView ? "nav-item-shrink" : ""}
              onClick={() => handleScrollToComponent("Trainings", -60, 1200)}
              // to="Trainings"
              // spy={true}
              // smooth={true}
              // offset={-60}
              // duration={1200}
              // activeClass="active"
            >
              {t("trainings.title")}
            </a>
          </li>
          <li>
            <a
              className={shrinkHeader && !tabView ? "nav-item-shrink" : ""}
              onClick={() => handleScrollToComponent("Career", -50, 1200)}
              // to="Career"
              // spy={true}
              // smooth={true}
              // offset={-50}
              // duration={1200}
              // activeClass="active"
            >
              {t("career.career")}
            </a>
          </li>
          <li>
            <a
              className={shrinkHeader && !tabView ? "nav-item-shrink" : ""}
              onClick={() => handleScrollToComponent("Contact", -60, 1200)}
              // to="Contact"
              // spy={true}
              // smooth={true}
              // offset={-60}
              // duration={1200}
              // activeClass="active"
            >
              {t("contact.contact")}
            </a>
          </li>
        </ul>
        <div className="region-container">
          <div className={"show-lang-container"}>
            <div
              onClick={() => handleChangeLocale("en")}
              className={selectedLocale === "en" ? "selected-locale" : "locale"}
            >
              EN
            </div>
            <div>|</div>
            <div
              onClick={() => handleChangeLocale("ge")}
              className={selectedLocale === "ge" ? "selected-locale" : "locale"}
            >
              DE
            </div>
          </div>
        </div>
        <div className="mobile-navbar-btn" onClick={toggleHandler}>
          <div
            className={hamburgerButtonClicked ? "nav-icon1 open" : "nav-icon1"}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          {/* <img
            src={images.hamburgerButton}
            alt="hamburger-btn"
            className="toggel-btn"
          /> */}
        </div>
      </header>
    </div>
  );
};
