import React from "react";
import "../../styles/contactUs.css";
import { images } from "../../images";
import '../../styles/footer.css';

export const Footer = () => {
  return (
    <>
    <div className="line"></div>
      <div className="icon-footer">
        <img src={images.footerLogo} className="logo-image" alt="logo" />
        <div className="icon-container">
          <a href="#">
            <img src={images.twitterIcon} alt="twitter" />
          </a>
          <a href="#" className="link">
            <img src={images.fbIcon} alt="facebook" />
          </a>
          <a href="#" className="link">
            <img src={images.linkedinIcon} alt="linkedin" />
          </a>
          <a href="#" className="link">
            <img src={images.pinterestIcon} alt="pinterest" />
          </a>
          <a href="#" className="link">
            <img src={images.instagramIcon} alt="instagram" />
          </a>
        </div>
      </div>
    </>
  );
};
