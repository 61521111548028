import "./App.css";
import React, { useEffect } from 'react';
import { ParallaxProvider } from "react-scroll-parallax";
import { Footer, Header } from "./components";
import { Home, LearnMorePage } from "./pages";
import { Route, Routes } from "react-router-dom";
import { ReadMorePage } from "./pages/readMore";
import { useTranslation } from "react-i18next";


function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const userLanguage = navigator.language;
    const countryCode = userLanguage.split('-')[0];
    switch (countryCode) {
      case 'de':
        i18n.changeLanguage('ge'); 
        break;
      default:
        i18n.changeLanguage('en'); 
        break;
    }
  }, [i18n]);
  return (
    <div className="App">

      <Header />
      <main>
      <ParallaxProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/learn-more" element={<LearnMorePage />} />
          <Route path="/read-more" element={<ReadMorePage /> } />
        </Routes>
       </ParallaxProvider>
      </main>

      <Footer />

    </div>
  );
}

export default App;
