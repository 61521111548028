const careerEn = {
  "career" : "Career",
  "submit":"Submit",
  "subLeft":" We are",
  "subRight":"hiring!",
  "paraContext":"Join us for an exciting career in the IT services industry. Need not to mention that we offer challenging projects, continuous learning, a collaborative environment, career progression, and a healthy work-life balance.",
  "applyHead":"Apply here!",
  "emailPlaceholder":"email address",
  "buttonContext":"attach cv/resume",
  "RightContentHead":"Open Positions",
  "RightItemFirst":"IT Sales consultant",
  "RightItemSecond":"Full stack developer MERN",
  "RightItemThird" :"DevOps Engineer",
  "RightItemFour":"AWS Cloud Architect",
  "BottomContent":" We pride ourselves in being truly non hierarchal and practice minimal or no managerial oversight based on the concept of self organizing teams. It is you who takes the lead where ever you can!",
  "sendYourCv" : "Send your CV/Resume at"
}

export default careerEn 
