const trainingsGe = {
  title: "Ausbildung",
  subTitle: "Die nächsten ... ",
  subTitleGreen: 'Full-Stack-Ninjas erschaffen',
  headerParaPrefix: 'Mit unserem ',
  headerPara: 'DeNSE ',
  headerParaSuffix:
    "(Define Next Startup Engine) Programm bieten wir ein leistungsstarkes Trainings-Bootcamp an. Das Programm garantiert hochqualifizierte und ausgebildete Full-Stack-Programmierer, die bereit sind, mit voller Kraft loszulegen.",
  dataHeading1: "Warum?",
  dataPara1:
    "Die nächste Generation von Startups und unternehmerischen Initiativen erfordert vielfältige Fähigkeiten und Agilität beim Aufbau ihrer Technologieprodukte. Diese Krieger verfügen über die richtige Munition, um eine dynamische Startup-Kultur zu unterstützen.",
  dataHeading2: "Wie?",
  dataPara2:
    "Talentierte und engagierte Kandidaten mit einer natürlichen Leidenschaft für das Programmieren werden nach einem sorgfältigen Auswahlprogramm ausgewählt, gefolgt von einem ebenso fokussierten, karriereorientierten, intensiven Trainings-Bootcamp für eine Dauer von bis zu 6 Monaten.",
  footerNote:
    "Das DenSE-Programm ist eine intensive, aber dennoch äußerst interessante und erlebnisreiche Reise für unsere zukünftigen Superstars. Das ist es, was ihnen Spaß macht, und wir sorgen dafür, dass es möglich ist!",
  footerNote2: "für kommende Trainingsprogramme",
  contact: "Kontakt",
};

export default trainingsGe;
