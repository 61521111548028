import { whoWeAreImages } from "../../../images";

const whoWeAreEn = {
  "title": "Who we are",
  "description": "We are committed to software innovation, design and engineering by co creating digital services and experiences with exceptional technical craftsmanship and passion.",
  "cardsText":[
    {
      image: whoWeAreImages.whoWeAre1,
      learnMoreImg:whoWeAreImages.bg1,
      title: "We are Design x Tech",
      description:
        "We believe in the fusion of creative design principles with cutting-edge technological advancements to drive innovation and create impactful solutions.",
    },
    {
      image: whoWeAreImages.whoWeAre2,
      learnMoreImg:whoWeAreImages.bg2,
      title: "Leverage AI, where we can.",
      description:
        "AI and ML can drive digital transformations by providing data-driven insights, automation, personalized experiences, and predictive analytics.",
    },
    {
      image: whoWeAreImages.whoWeAre3,
      learnMoreImg:whoWeAreImages.bg3,
      title: "People over processes",
      description:
        "Straight out from the Agile manifesto, we value individuals and their needs more over processes and procedures.",
    },
    {
      image: whoWeAreImages.whoWeAre4,
      learnMoreImg:whoWeAreImages.bg4,
      title: "Inherently Agile",
      description:
        "Our DNA has built-in flexibility, adaptability, and responsiveness, allowing for iterative and incremental development, quick feedback loops, and continuous improvement.",
    },
  ],
  "readMore":'Read More'
};

export default whoWeAreEn;
