const whatWeDoGe ={
    "WhatWeDo": "Was wir tun",
    "subHeading":"Verwandeln Sie Ihre Vision in die Realität – Schritt für Schritt.",
    "learnMore":"Kontaktieren Sie uns, um mehr zu erfahren",
    "categories": {
      "Digital transformations": {
        id: 1,
        heading: 'Digitale Transformationen',
        content: 'Wir sind Partner bei der Einführung und Integration digitaler Technologien und Strategien im gesamten Unternehmen, um die Art und Weise, wie es funktioniert, Werte liefert und mit Stakeholdern interagiert, grundlegend zu verändern. Nutzen Sie digitale Tools, Datenanalysen, Automatisierung, Cloud Computing, künstliche Intelligenz und andere neue Technologien, um Abläufe zu rationalisieren, das Kundenerlebnis zu verbessern, Geschäftsprozesse zu optimieren und Innovationen voranzutreiben.'
      },
      "Design services": {
        id: 2,
        heading: 'Designdienstleistungen',
        content: 'Wir sind stolz darauf, großartige visuelle und interaktive Elemente für digitale Plattformen wie Websites, mobile Anwendungen, Benutzeroberflächen und andere digitale Medien zu erstellen. Durch den Einsatz verschiedener Designtools und -techniken zur Konzeption und Gestaltung produzieren wir visuelle Assets, die ästhetisch ansprechend und benutzerfreundlich sind und auf den beabsichtigten Zweck und die Markenidentität abgestimmt sind.',
        subcontent: 'Digitales Design umfasst Aspekte wie User Experience (UX)-Design, User Interface (UI)-Design, Grafikdesign, Informationsarchitektur und interaktives Prototyping. Der Schwerpunkt liegt auf der Schaffung ansprechender und intuitiver digitaler Erlebnisse, die Informationen effektiv kommunizieren, die Benutzerfreundlichkeit verbessern und positive Benutzerreaktionen hervorrufen.'
      },
      "AI adoption": {
        id: 3,
        heading: 'KI-Einführung',
        content: 'Wir bieten umfassende KI- und ML-Entwicklungsdienste, einschließlich Strategieentwicklung, Datenaufbereitung, Modellentwicklung, Integration und fortlaufender Unterstützung, um Organisationen dabei zu helfen, KI effektiv zu implementieren und Geschäftsziele zu erreichen.'
      },
      "": {
        id: 4,
        heading: '',
        content: '',
        disabled: true
      },
      "Low Code": {
        id: 5,
        heading: 'Low-Code',
        content: 'Wir bieten auch Low-Code-Entwicklung und -Beratung als alternativen Ansatz an, der die Softwareentwicklung durch die Verwendung visueller Schnittstellen und vorgefertigter Komponenten vereinfacht und so den Bedarf an manueller Codierung reduziert. Es ermöglicht eine schnellere Anwendungsentwicklung und ermöglicht Entwicklern unterschiedlicher Qualifikationsniveaus, Anwendungen effizient zu erstellen. Mit Low-Code-Entwicklung können Unternehmen den Entwicklungsprozess rationalisieren, die Markteinführungszeit verkürzen und eine größere Agilität bei ihren Softwareentwicklungsinitiativen fördern.'
      },
      "DevOps and Devsecops": {
        id: 6,
        heading: 'DevOps und Devsecops',
        content: 'DevOps ist in unseren Entwicklungspraktiken integriert. Dabei geht es um die Automatisierung der Softwareentwicklung, des Testens, der Bereitstellung und des Infrastrukturmanagements, was schnellere und häufigere Releases ermöglicht. Durch die Förderung einer Kultur der Zusammenarbeit fördert DevOps gemeinsame Verantwortung, kontinuierliche Integration und kontinuierliche Bereitstellung und ermöglicht es Unternehmen, qualitativ hochwertige Softwareprodukte mit kürzeren Entwicklungszyklen und schnellerer Markteinführung bereitzustellen.'
      },
      "Team augmentation": {
        id: 7,
        heading: 'Teamverstärkung',
        content: 'Als vertrauenswürdiger und flexibler Partner sind wir darauf spezialisiert, qualifizierte Fachkräfte oder engagierte Teams zur Verstärkung Ihrer bestehenden Belegschaft bereitzustellen. Unser Pool an qualifizierten und erfahrenen Kollegen kann sich nahtlos in Ihr Team integrieren und bei der Projektentwicklung oder bei spezifischen Aufgaben mitwirken.'
      },
      "": {
        id: 8,
        heading: '',
        content: '',
        disabled: true
      },
      "Cloud native aka cloud first": {
        id: 9,
        heading: 'Cloud Native, auch Cloud First genannt',
        content: 'Mit der cloudnativen Entwicklung können Unternehmen skalierbare On-Demand-Computing-Ressourcen nutzen, was eine schnelle Bereitstellung und Innovation von Anwendungen, Diensten und Infrastruktur ermöglicht und gleichzeitig die Wartungs- und Betriebskosten senkt.',
      }
    }
  }
  
  export default whatWeDoGe;