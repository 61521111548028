import React from 'react';
import Input from './input';


export const Contact = () => {
 return (
    <div>
        <Input />
    </div>
 );
}

