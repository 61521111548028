import React from "react";
import { images } from "../images";
import "./career.css";
import { useTranslation } from "react-i18next";

export const Career = () => {
  const { t } = useTranslation("translation", { keyPrefix: "career" });

  return (
    <div className="main-container">
      <div className="top-section">
        <div className="career-heading-container">
          <h1 className="career-heading"> {t("career")} </h1>
          <img src={images.careerUnderline} alt="" />
        </div>
        <div className="rightarrowsection">
          <img src={images.arrowRightImage} alt="right-arrow" />
        </div>
      </div>

      <div className="main-section">
        <div className="left-section">
          <div className="left-top-section">
          <h1 className="career-sub-section-heading">
            {" "}
            {t("subLeft")} <span className="green-text">{t("subRight")}</span>
          </h1>
          <div className="para-content">
            <p>{t("paraContext")}</p>
          </div>
          </div>
          <div className="apply-part">
          <p>{t("sendYourCv")} <span>connect@definenext.com</span></p>
          </div>
        </div>
        <div className="right-section">
          <h1 className="right-heading"> {t("RightContentHead")}</h1>
          <div className="right-content">
            <li>{t("RightItemFirst")}</li>
            <li>{t("RightItemSecond")} </li>
            <li>{t("RightItemThird")}</li>
            <li>{t("RightItemFour")}</li>
          </div>
        </div>
      </div>
      <hr className="vectorline" />
      <div className="bottom-content">
      <p>{t("BottomContent")}</p>
      </div>
    </div>
  );
};
