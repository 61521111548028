const industriesEn = {
  "Industries" : "Industries" ,
  "Industries Sub Title": "Our tech services drive innovation and provide ",
  "Industries Sub Title Bold": "tailored solutions across diverse domains.",
  "industriesCategory": {
    "Retail and e-commerce": {
      title: "retail and e-commerce",
      detail:
        "Empowering businesses with tech solutions to enhance customer experiences and streamline online operations. We have provided technical solutions for connected vehicle ecosystem and shared mobility.",
    },
    "Financial services": {
      title: "financial services",
      detail:
        "Delivering cutting-edge technology to enable secure transactions, optimize processes, and ensure regulatory compliance in the financial sector.",
    },
    "Healthcare and life sciences": {
      title: "healthcare and life sciences",
      detail:
        "Transforming healthcare delivery and advancing medical research through innovative tech solutions, improving patient care and outcomes.",
    },
    "Clean tech": {
      title: "clean tech",
      detail:
        "Revolutionizing energy production, conservation, and sustainability through technology-driven solutions for a greener future.",
    },
    "Internet of things (iot)": {
      title: "Internet of Things (IoT)",
      detail:
        "Connecting devices and enabling intelligent data exchange to enhance productivity, efficiency, and automation across various sectors.",
    },
    "Travel and transport": {
      title: "travel and transport",
      detail:
        "Enabling seamless travel experiences and optimizing logistics through tech-driven solutions for the transportation and hospitality industries.",
    },
    "Non-for-profit": {
      title: "non-for-profit",
      detail:
        "Providing tech services to support the digital transformation of nonprofit organizations, helping them reach their missions more effectively.",
    },
    "Automative": {
      title: "automotive",
      detail:
        "Leveraging technology to drive innovation and efficiency in the automotive industry",
    }
  }
}

export default industriesEn 