import whatWeDo from "./what-we-do.webp";
import IndustriesPageImage from "./unsplash_uPYpcsbICI4.webp";
import whoWeAre1 from "./who-we-are-1.webp";
import whoWeAre2 from "./who-we-are-2.webp";
import whoWeAre3 from "./who-we-are-3.webp";
import whoWeAre4 from "./who-we-are-4.webp";
import AiRectangle from "./AI-Rectangle.webp";
import arrowBigImage from "./arrow.png";
import cloud from "./cloud.png";
import careerImage from "./career.webp";
import vectorImage from "./vector.jpg";
import symbolImage from "./symbol.png";
import arrowImage from "./arrow.jpg";
import arrowRightImage from "./arrow1.jpg";
import leftArrow from "./leftArrow.svg";
import rightArrow from "./rightArrow.svg";
import minLeftButton from "./minLeftButton.svg";
import modernize1 from "./modernize1.png";
import modernize2 from "./modernize2.png";
import optimize1 from "./optimize1.webp";
import optimize2 from "./optimize2.webp";
import dot from "./dot.png";
import dotLight from "./dot-light.png";
import insightArrow from "./insight-arrow.png";
import defineNextLogo from "./define-next-logo.png";
import instagram from "./instagram.png";
import facebook from "./facebook.png";
import twitter from "./twitter.png";
import pinterest from "./pinterest.png";
import linkedin from "./linkedin.png";
import training1 from "./trainings1.webp";
import training2 from "./trainings2.webp";
import leftArrowFill from "./leftArrowFill.png";
import upSideArrow from "./upSideArrow.png";
import hamburgerButton from "./hamburger-btn.svg";
import world from "./world_alt_light.svg";
import logo from "./logo.svg";
import housingLogo from "./brands/Housing.com_logo 1.png";
import koovsLogo from "./brands/KOOVS 1.png";
import mamaearthLogo from "./brands/mamaearth-logo 1.png";
import scoopwhoopLogo from "./brands/scoopwhoop.png";
import snapdealLogo from "./brands/Snapdeal-Logo 1.png";
import yayzyLogo from "./brands/YAYZY-Limited 1.png";
import zellerLogo from "./brands/zeller_owler.png";
import smallArrow from './smallArrow.svg'
import cross from './close-cross.svg';
import rightArrowWWR from './rightArrowWWR.png';
import Location from "./Pin_light.png";
import Phone from "./phone.svg";
import Mail from "./Message_light.png";
import Map from './Map.svg'
import aiAdoption from './ai-adoption.png';
import designServices from './design-services.png';
import devops from './devops.png';
import digitalTransformations from './digital-transformations.png';
import lowCode from './low-code.png';
import teamAugmentation from './team-augmentation.png';
import underlineBlue from './underline-blue.svg';
import underlineWhite from './underline-white.svg';
import careerUnderline from './career-underline.svg';
import contactUnderline from './contact-underline.svg';
import industriesUnderline from './industries-underline.svg';
import insightsUnderline from './insights-underline.svg';
import trainingsUnderline from './trainings-underline.svg';
import whatWeDoUnderline from './what-we-do-underline.svg';
import whoWeAreUnderline from './who-we-are-underline.svg';
import footerLogo from './footer-logo.webp';
import instagramIcon from './instagram.svg';
import twitterIcon from './twitter.svg';
import fbIcon from './fb.svg';
import pinterestIcon from './pinterest.svg';
import linkedinIcon from './linkedin.svg';
import globeIcon from './globe.png';
import  bg1 from './bg1.png';
import  bg2 from './bg2.png';
import  bg3 from './bg3.png';
import  bg4 from './bg4.png';
import worldMap from './worldMap.webp';
import blueUnderline from './blue-underline.svg';
import singleUnderlineBlue from './single-underline-blue.svg'
import emailIcon from './email-icon.svg';
import phoneIcon from './phone-icon.svg';
import pinIcon from './pin-icon.svg';




export const whoWeAreImages = { whoWeAre1, whoWeAre2, whoWeAre3, whoWeAre4, bg1, bg2,bg3,bg4 };
export const arrow = { arrowBigImage };
export const images = {
  emailIcon,
  phoneIcon,
  pinIcon,
  singleUnderlineBlue,
  blueUnderline,
  globeIcon,
  instagramIcon,
  twitterIcon,
  pinterestIcon,
  fbIcon,
  linkedinIcon,
  footerLogo,
  whatWeDo,
  IndustriesPageImage,
  whoWeAre1,
  cloud,
  minLeftButton,
  rightArrow,
  leftArrow,
  careerImage,
  vectorImage,
  symbolImage,
  arrowImage,
  defineNextLogo,
  instagram,
  facebook,
  twitter,
  pinterest,
  linkedin,
  AiRectangle,
  rightArrow,
  leftArrow,
  leftArrowFill,
  upSideArrow,
  arrowRightImage,
  hamburgerButton,
  world,
  logo,
  smallArrow,
  arrowBigImage,
  cross,
  housingLogo,
  koovsLogo,
  mamaearthLogo,
  scoopwhoopLogo,
  snapdealLogo,
  yayzyLogo,
  zellerLogo,
    rightArrowWWR,
    Location,
    Phone,
    Mail,
    Map,
    aiAdoption,
    designServices,
    devops,
    digitalTransformations,
    lowCode,
    teamAugmentation,
    underlineBlue,
    underlineWhite,
    careerUnderline,
    contactUnderline,
    industriesUnderline,
    insightsUnderline,
    trainingsUnderline,
    whatWeDoUnderline,
    whoWeAreUnderline,
    worldMap
}
  

export const insightsImages = {
  optimize1,
  optimize2,
  modernize1,
  modernize2,
  insightArrow,
  dot,
  dotLight,
};
export const trainingImages = { training1, training2 };
