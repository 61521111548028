import { whoWeAreImages } from "../../../images";

const whoWeAreGe = {
  "title": "Wer wir sind",
  "description":"Wir engagieren uns für Software-Innovation, Design und Engineering, indem wir gemeinsam mit außergewöhnlicher technischer Handwerkskunst und Leidenschaft digitale Dienste und Erlebnisse schaffen.",
  "cardsText":[
    {
      image: whoWeAreImages.whoWeAre1,
      learnMoreImg: whoWeAreImages.bg1,
      title: "Design x Tech",
      description:
        "Wir glauben an die Verschmelzung kreativer Designprinzipien mit modernsten technologischen Fortschritten, um Innovationen voranzutreiben und wirkungsvolle Lösungen zu schaffen.",
    },
    {
      image: whoWeAreImages.whoWeAre2,
      title: "KI nutzen, wo wir können.",
      description:
        "KI und ML können digitale Transformationen vorantreiben, indem sie datengesteuerte Erkenntnisse, Automatisierung, personalisierte Erlebnisse und prädiktive Analysen bereitstellen.",
    },
    {
      image: whoWeAreImages.whoWeAre3,
      title: "Menschen über Prozesse",
      description:
        "Aus dem Agile-Manifest geht hervor, dass wir Einzelpersonen und ihre Bedürfnisse mehr wertschätzen als Prozesse und Verfahren.",
    },
    {
      image: whoWeAreImages.whoWeAre4,
      title: "Von Natur aus agil",
      description:
        "Unsere DNA verfügt über integrierte Flexibilität, Anpassungsfähigkeit und Reaktionsfähigkeit, die eine iterative und inkrementelle Entwicklung, schnelle Feedbackschleifen und kontinuierliche Verbesserung ermöglicht.",
    },
  ],
  "readMore":'Weiterlesen'
};

export default whoWeAreGe;
