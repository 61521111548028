const trainingsEn = {
  title :'Trainings',
  subTitle: 'Creating the next... ',
  subTitleGreen: 'Full stack Ninjas',
  headerParaPrefix: 'With our ',
  headerPara: 'DeNSE ',
  headerParaSuffix:'(Define Next Startup Engine) program we offer a power packed training bootcamp. The program guarantees highly skilled and trained full stack programmers who are ready to roll with a bang.',
  dataHeading1: 'Why?',
  dataPara1: 'The next generation of startups and entrepreneurial initiatives require multiple skills and agility in building their technology products. These warriors have all the right ammunition to support a dynamic startup culture.',
  dataHeading2: 'How?',
  dataPara2: 'Talented and dedicated candidates with a natural passion for programming are chosen after a careful selection program. It is followed by a career oriented and focussed training bootcamp for a period of 4 to 6 months.',
  footerNote: 'Please note that the DenSE program is an intense yet highly interesting and experiential journey for our future superstars. This is what they enjoy and we ensure to make it possible!',
  footerNote2: 'for upcoming training schedules',
  contact: 'Contact us'
};

export default trainingsEn;
