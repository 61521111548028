import React, { useEffect } from "react";
import "../../styles/navbar.css";
import NavbarItem from "./NavbarItem";
import { images } from "../../images";
import { Link, scroller } from "react-scroll";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const navbarItems = [
  {
    id: "WhoWeAre",
    name: "who we are",
    transKey: "whoWeAre.title",
    offset: 10,
  },
  {
    id: "WhatWeDO",
    name: "what we do",
    transKey: "WhatWeDo.WhatWeDo",
    offset: -40,
  },
  {
    id: "Industries",
    name: "industries",
    transKey: "Industries.Industries",
    offset: -60,
  },
  // {
  //   id: "Insights",
  //   name: "insight",
  //   transKey: "insights.insights",
  //   offset: -80,
  // },
  {
    id: "Trainings",
    name: "trainings",
    transKey: "trainings.title",
    offset: -45,
  },
  { id: "Career", name: "career", transKey: "career.career", offset: -40 },
  {
    id: "Contact",
    name: "contact us",
    transKey: "contact.contact",
    offset: -40,
  },
];

const NavbarModal = ({ toggleHandler, changeHamburger }) => {
  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   }
  // }, []);
  const [isNavHidden, setIsNavHidden] = useState(false);
  const handleNavClick = (contentType, offset, duration) => {
    return (
      (document.body.style.overflow = "auto"),
      setIsNavHidden(true),
      changeHamburger(contentType, offset, duration)
    );
  };
  return (
    <div className={!isNavHidden ? "modal-container" : "hide-modal"}>
      {/* <div className="modal-header">
        <img src={images.logo} alt="logo" className="navbarModal-logo" draggable='false'/>
        <button type="button" className="navbar-close-button">
          <img src={images.cross} alt="cross button" onClick={toggleHandler} />
        </button>
      </div> */}
      <hr className="navbar-hor-line full" />
      {navbarItems.map((item) => (
        <a
          onClick={() => handleNavClick(item.id, item.offset, 500)}
          // to={item.id}
          // smooth={true}
          // offset={item.offset}
          // duration={500}
        >
          <NavbarItem key={item.id} text={item} />
        </a>
      ))}
    </div>
  );
};

export default NavbarModal;
