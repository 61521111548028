import industriesGe from './industriesGe'
import careerGe from './careerGe'
import contactGe from './contactGe'
import insightsGe from './insightsGe'
import landingGe from './landingGe'
import learnMoreGe from './learnMoreGe'
import trainingsGe from './trainingsGe'
import whoWeAreGe from './whoWeAreGe'
import whatWeDoGe from './whatWeDoGe'

const  germanTranslation = {
    "Welcome to React": "Welcome to React and react-i18next",
    "Industries": industriesGe  ,
    "career"  : careerGe,
    "contact"   :contactGe,
    "insights"  :insightsGe,
    "landing" :landingGe,
    "learnMore" : learnMoreGe,
    "trainings" :trainingsGe,
    "whoWeAre" : whoWeAreGe,
    "WhatWeDo" : whatWeDoGe
    
  }

export  default germanTranslation