const careerGe = {
  "career" : "Karriere",
  "submit":"Einreichen",
  "subLeft":" Wir",
  "subRight":"stellen ein!",
  "paraContext":"Begleiten Sie uns auf eine spannende Karriere in der IT-Dienstleistungsbranche. Wir bieten u.a. anspruchsvolle Projekte, kontinuierliches Lernen, ein kollaboratives Umfeld, berufliche Weiterentwicklung und eine gesunde Work-Life-Balance.",
  "applyHead":"Hier bewerben!",
  "emailPlaceholder":"E-Mail-Addresse",
  "buttonContext":"attach cv/resume",
  "RightContentHead":"Offene Stellen",
  "RightItemFirst":"IT-Presales-Berater",
  "RightItemSecond":"Full-Stack-Entwickler MERN",
  "RightItemThird" :"DevOps-Ingenieur",
  "RightItemFour":"AWS Cloud-Architekt",
  "BottomContent":" Wir sind stolz darauf, nicht hierarchisch zu sein und praktizieren nur minimale oder gar keine Managementaufsicht, basierend auf dem Konzept selbstorganisierender Teams. Sie sind es, die die Führung übernehmen, wo immer Sie können!",
  "sendYourCv":"Senden Sie Ihren Lebenslauf und Bewerbung an"
}

export default careerGe 
