import { useState, useEffect } from "react";
import { arrow, images } from "../../images";
import Card from "./card";
import "../../styles/whoWeAre.css";
import { useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";

export const WhoWeAre = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'whoWeAre' } );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(0);
  const cards = t("cardsText", { returnObjects: true });
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 767;
  const isTablet = windowWidth > 767 && windowWidth <= 1023;

  const cardsPerPage = isMobile ? 1 : isTablet ? 2 : 4;
  const totalSlides = Math.ceil(4 / cardsPerPage);  
  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };
  const parallax = useParallax({
    speed: isMobile ? 0 :-5,
  });

  return (
    <div ref={parallax.ref} className="container">
      <div className="heading">
        <div class="title-container-whoweare"><div className="title-whoweare">{t(`title`)}</div>
        <img src={images.blueUnderline} alt="" />
        </div>

        {/* <img className="heading-arrow" src={images.upSideArrow} alt="arrow" /> */}
      </div>
      <div className="content">
        <div className="description">{t(`description`)}</div>

        {/* <div className="arrow">
          <img src={arrow.arrowBigImage} alt="vector-arrow" />
        </div> */}
      </div>
      <div className="line-break"></div>
      <div className={`card-container ${isMobile ? "slides" : ""}`}>
        {cards.map((cardData, index) => {
          const cardIndex = isMobile ? index : Math.floor(index / cardsPerPage);
          const isFirstCard = cardIndex === 0;
          const isLastCard = cardIndex === totalSlides - 1;
          const isVisible = cardIndex === currentSlide;

          return (
            <Card
              key={index}
              cardData={cardData}
              isFirstCard={isFirstCard}
              isLastCard={isLastCard}
              isVisible={isVisible}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          );
        })}
      </div>
      {isMobile || isTablet ? (
        <div className="whoWeAre-arrow-container">
          {(currentSlide !== 0 )&& (
            <div className="prev-arrow" onClick={handlePrevSlide}>
              <img src={images.rightArrow} className="prev-arrow-image" alt="previous-arrow" />
            </div>
          )}
           {(currentSlide === 0 )&& (
            <div className="prev-arrow" >
              <img src={images.leftArrow} alt="previous-arrow" />
            </div>
          )}
          {(currentSlide !== totalSlides - 1) && (
            <div className="next-arrow" onClick={handleNextSlide}>
              <img src={images.rightArrow} alt="next-arrow" />
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};