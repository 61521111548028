import "../styles/learnMore.css";
import { arrow } from "../images";

export const ReadMore = ({data}) => {
    return (
        <div className="learn-more-container">
        <div className="learn-more-header">
          <img src={data.learnMoreImg} className='ai-image' alt="AI-Rectangle"></img>
        </div>
        <img
          src={arrow.arrowBigImage}
          className="learn-more-arrow"
          alt="AI-Rectangle"
        ></img>
        <div className="learn-more-content">
          <div className="learn-more-title">
            {data.title}
          </div>
          <div className="learn-more-text-container">
             <div className="learn-more-text">{data.description}</div>
          </div>
        </div>
      </div>
    )
}