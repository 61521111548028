import { WhatWeDO,WhoWeAre, LandingPage , Career,Contact, Industries, Insights, Trainings} from '../components';

export const Home = ()=>{
    return (
        <>
          <div  id="LandingPage" > <LandingPage /> </div> 
          <div  id="WhoWeAre" > <WhoWeAre/></div> 
          <div  id="WhatWeDO" > <WhatWeDO /> </div> 
          <div  id="Industries" style={{ borderBottom: '2px solid #192057'}} > <Industries /></div> 
          {/* keep insights section hidden till blogs not implemented */}
          {/* <div id="Insights" > <Insights/> </div>  */}
          <div id="Trainings"  > <Trainings/> </div>
          <div  id="Career" > <Career /></div> 
          <div id="Contact"  >  <Contact /> </div>
        </>
    )
}
