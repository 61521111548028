import "../../styles/learnMore.css";

import { arrow, images } from "../../images";
import { useTranslation } from "react-i18next";

export const LearnMore = (props) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'learnMore' } );
  const learnMoreText = t("learnMore", { returnObjects: true });
  return (
    <div className="learn-more-container">
      <div className="learn-more-header">
        <img src={images.AiRectangle} className='ai-image' alt="AI-Rectangle"></img>
      </div>
      <img
        src={arrow.arrowBigImage}
        className="learn-more-arrow"
        alt="AI-Rectangle"
      ></img>
      <div className="learn-more-content">
        <div className="learn-more-title-container">
        <div className="learn-more-title">
          {t(`title`)}
        </div>
        <img src={images.singleUnderlineBlue} alt="" />
        </div>
        <div className="learn-more-text-container">
          {learnMoreText.map((text) => {
            return <div className="learn-more-text">{text}</div>;
          })}
        </div>
      </div>
    </div>
  );
};
