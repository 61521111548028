import WhatWeDoEn from './WhatWeDoEn'
import careerEn from './careerEn'
import contactEn from './contactEn'
import industriesEn from './industriesEn'
import insightsEn from './insightsEn'
import landingEn from './landingEn'
import learnMoreEn from './learnMoreEn'
import trainingsEn from './trainingsEn'
import whoWeAreEn from './whoWeAreEn'
const  englishTranslation = {
    "Welcome to React": "Welcome to React and react-i18next",
    "Industries": industriesEn  ,
    "career"  : careerEn,
    "contact"   :contactEn,
    "insights"  :insightsEn,
    "landing" :landingEn,
    "learnMore" : learnMoreEn,
    "trainings" :trainingsEn,
    "whoWeAre" : whoWeAreEn,
    "WhatWeDo" : WhatWeDoEn
  }

export  default englishTranslation