const contactEn = {
    title:'Contact Us',
    submit:'Submit',
    contact : 'Contact us',
    support: 'Support',
    formHeading:'Get in touch',
    namePlaceholder: 'name',
    emailPlaceholder:'email Address',
    textPlaceholder: 'text message',
    addressTitle: 'Address',
    address: '89, Sector 55, Golf Course Road, Gurugram, India',
    contactNum:' +91-8285441187'
  }
  
  export default contactEn 
