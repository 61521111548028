import React from "react";
import { images } from "../../images";
import "../../styles/contactUs.css";
import { useTranslation } from "react-i18next";

const Input = () => {

  const { t } = useTranslation('translation', { keyPrefix: 'contact' } );
  const handleEmailClick = () => {
    window.location.href = 'mailto:connect@definenext.com';
  }
  return (
    <div className="contact-us-body">
      <div className="contact-title-container"><h1 className="contact">{t(`title`)}</h1><img src={images.contactUnderline} alt="" /></div>
      <h1 className="touch">{t(`formHeading`)}</h1>
      <div className="contact-us-container">
        <div className="contact-left-section">
          <div className="border-right">
          <div>
          <h6>{t('support')}</h6>
          <div className="contact-icon-container">
            <img src={images.emailIcon} alt="emailIcon" />
          <p className="email" onClick={handleEmailClick}>connect@definenext.com</p>
          </div>
          <div className="contact-icon-container"> 
          <img src={images.phoneIcon} alt="phoneIcon" />
          <p>{t(`contactNum`)}</p>
          </div>
          </div>
          <div style={{marginTop:'20px'}}>
            <h6>{t('addressTitle')}</h6>
            <div className="contact-icon-container">
            <img src={images.pinIcon} alt="pinIcon" />
            <p>{t(`address`)}</p>
            </div>
          </div>
          </div>
        </div>
        <div className="contact-right-section">
          <div className="map-container">
          <img src={images.worldMap} alt="" className="world-map" />
          </div>
          </div>
      </div>
    </div>
  );
};

export default Input;
