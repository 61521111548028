const contactGe = {
  title: "Kontakt",
  submit: "Einreichen",
  contact : 'Kontakt',
  support: 'Define Next Innovations Pvt. Ltd.',
  formHeading: "Ihr Kontakt zu uns",
  namePlaceholder: "name",
  emailPlaceholder: "E-Mail-Addresse",
  textPlaceholder: "Textnachricht",
  addressTitle: 'Adresse',
  address: '89, Sector 55, Golf Course Road, Gurugram, India',
  contactNum:'+49 1517 5407497'
};

export default contactGe;
