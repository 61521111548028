import { trainingImages } from "../images";


export const trainingHeadingTitle = ['Creating the next... ', 'Full stack Ninjas'];

const trainingData1 = {
    image: trainingImages.training1,
    title:'Why?',
    text: 'Specially aimed to assist the next generation of startups and entrepreneurial initiatives in building their technology products, these warriors have all the right ammunition to support a dynamic startup culture which requires multiple skills and agility.'
};


const trainingData2 = {
    image: trainingImages.training2,
    title:'How?',
    text: 'Talented and dedicated candidates with a natural passion for programming are chosen after a careful selection program followed by an equally focused career oriented intense training bootcamp for a duration of upto 6 months.'
};

const footerText = 'Please note that this is not a monotonous or stressful regime but a highly interesting and enjoyable journey for our future superstars. Why? Because this is what they enjoy and we ensure to make it possible!';

export default {
    trainingHeadingTitle,
    trainingData1,
    trainingData2,
    footerText
};