import React, { useState,useEffect } from 'react'
import { images } from '../images'
import '../styles/whatWeDo.css';
import { useTranslation } from 'react-i18next';
import { ParallaxBanner, ParallaxBannerLayer, useParallax } from 'react-scroll-parallax';


export const WhatWeDO = () => {

    const { t, i18n } = useTranslation('translation', { keyPrefix: 'WhatWeDo' });
    const data = [
        {
            id: 1,
            heading: 'Digital transformations',
            content: 'We partner in adopting and integrating digital technologies and strategies across an organization to fundamentally change how it operates, delivers value, and interacts with stakeholders. Leveraging digital tools, data analytics, automation, cloud computing, artificial intelligence, and other emerging technologies to streamline operations, enhance customer experiences, optimize business processes, and drive innovation.',
            svg: images.digitalTransformations
        },
        {
            id: 2,
            heading: 'Design services',
            content: 'We take pride in creating awesome visual and interactive elements for digital platforms, such as websites, mobile applications, user interfaces, and other digital media. With the use of various design tools and techniques to conceptualize, layout, we produce visual assets that are aesthetically pleasing, user-friendly, and aligned with the intended purpose and brand identity.',
            subcontent: 'Digital design encompasses aspects such as user experience (UX) design, user interface (UI) design, graphic design, information architecture, and interactive prototyping. It focuses on creating engaging and intuitive digital experiences that effectively communicate information, enhance usability, and evoke positive user responses.',
            svg: images.designServices
        },
        {
            id: 3,
            heading: 'AI adoption',
            content: 'We offer comprehensive AI and ML development services, including strategy development, data preparation, model development, integration, and ongoing support, to help organizations effectively implement AI and achieve business goals.',
            svg: images.aiAdoption
        },
        {
            id: 4,
            heading: '',
            content: '',
            disabled: true
        },
        {
            id: 5,
            heading: 'Low Code',
            content: 'We also provide low-code development and consultancy as an alternative approach that simplifies software development by using visual interfaces and pre-built components, reducing the need for manual coding. It enables faster application development and empowers developers of various skill levels to create applications efficiently. With low-code development, organizations can streamline the development process, accelerate time-to-market, and foster greater agility in their software development initiatives.',
            svg: images.lowCode
        },
        {
            id: 6,
            heading: 'DevOps and Devsecops',
            content: 'DevOps is built in our development practices. It involves the automation of software development, testing, deployment, and infrastructure management, allowing for faster and more frequent releases. By fostering a culture of collaboration, DevOps promotes shared responsibility, continuous integration, and continuous delivery, enabling organizations to deliver high-quality software products with shorter development cycles and quicker time-to-market.',
            svg: images.devops
        },
        {
            id: 7,
            heading: 'Team augmentation',
            content: 'As a trusted and flexible partner we specialize in providing skilled professionals or dedicated teams to augment your existing workforce. Our pool of qualified and experienced colleauges can seamlessly integrate into your team and contribute to project development or specific tasks.',
            svg: images.teamAugmentation
        },
        {
            id: 8,
            heading: '',
            content: '',
            disabled: true
        },
        {
            id: 9,
            heading: 'Cloud native aka cloud first',
            content: 'Cloud native development enables businesses to leverage scalable, on-demand computing resources, empowering rapid deployment and innovation of applications, services, and infrastructure, all while reducing maintenance and operational costs.',
            svg: images.cloud
        },
    ]
    const [selectedItem, setSelectedItem] = useState(data[8]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isMobile = windowWidth <= 767;
    const handleOnlickEvent = (item) => {
        if (!item.disabled) {
            setSelectedItem(item);
        }
    }
    const parallax = useParallax({
        speed: isMobile ? 5 : -2,
    });

    return (
        <div ref={parallax.ref} className="container-what-we-do">
            <ParallaxBanner style={{ aspectRatio: '3 / 2', height: '100%' }}>
                <ParallaxBannerLayer image={images.whatWeDo} speed={-20} className="background-image" />
            </ParallaxBanner>
            {/* <img src={images.whatWeDo} alt="what-we-do background" className="background-image" /> */}
            <div className="layout">
                <div className="title-whatwedo">
                <h6> {t(`WhatWeDo`)}</h6>
                <img src={images.whatWeDoUnderline} alt="" />
                </div>
                <div className="content-container">
                    <div className="left-container">
                        <div className="heading-h2">
                            <h2>{t(`subHeading`)}</h2>
                        </div>
                        <div className="icons">
                            <div className="whatwedo-icon-container">
                            <img src={selectedItem.svg} alt="icons" />
                            </div>
                        </div>
                        <div className="leftContent">
                            <div>
                                {selectedItem && <p className="sub-text">
                                    {t(`categories.${selectedItem.heading}.content`)}
                                </p>}
                            </div>
                            <div className="connect-with-us">
                                <p>{t(`learnMore`)}</p>
                                <div className="whatwedo-bar">
                                    <div className="green-bar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-container">
                        <ul className="unorderd-list">
                            {data.map((item) => {
                                return (
                                    <li className={item.disabled ? "faded-container" : item.id === selectedItem.id ? "selected-container" : "list-container"}>
                                        <div onMouseEnter={() => handleOnlickEvent(item)} onClick={() => handleOnlickEvent(item)} className={item.id === selectedItem.id ? "selectedlist" : "list"}><p>{t(`categories.${item.heading}.heading`)}</p></div>
                                        {item.id === selectedItem.id && <img className="mini-arrow" src={images.minLeftButton} alt="" />}
                                        {/* {index === data.length-1 && <div className="small-triangle"></div>} */}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}


