import { insightsImages } from "../../../images/";
const insightsEn = {
  insights: "Insights",
  "cardsText" :[{
    img:insightsImages.modernize2,
    title: "Optimize-1",
    details:
      "Understanding infrastructure as Code : How it works and its benefits",
    date: "Article - 22 May 2023",
  },
  {
    img:insightsImages.optimize1,
    title: "Optimize-2",
    details:
      "Understanding infrastructure as Code : How it works and its benefits",
    date: "Article - 22 May 2023",
  },
  {
    img:insightsImages.modernize1,
    title: "Optimize-3",
    details:
      "Understanding infrastructure as Code : How it works and its benefits",
    date: "Article - 22 May 2023",
  },
  {
    img:insightsImages.optimize2,
    title: "Optimize-4",
    details:
      "Understanding infrastructure as Code : How it works and its benefits",
    date: "Article - 22 May 2023",
  }]
};

export default insightsEn;