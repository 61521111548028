import React, { useState,useEffect } from "react";
import "./industries.css";
import { images } from "../../images/index";
import "../../styles/whatWeDo.css";
import { useTranslation } from "react-i18next";
import { useParallax } from "react-scroll-parallax";

export const Industries = () => {
  let industries = [
    {
      title: "Retail and e-commerce",
      detail:
        "Empowering businesses with tech solutions to enhance customer experiences and streamline online operations. We have provided technical solutions for connected vehicle ecosystem and shared mobility.",
    },
    {
      title: "Financial services",
      detail:
        "Delivering cutting-edge technology to enable secure transactions, optimize processes, and ensure regulatory compliance in the financial sector.",
    },
    {
      title: "Healthcare and life sciences",
      detail:
        "Transforming healthcare delivery and advancing medical research through innovative tech solutions, improving patient care and outcomes.",
    },
    {
      title: "Clean tech",
      detail:
        "Revolutionizing energy production, conservation, and sustainability through technology-driven solutions for a greener future.",
    },
    {
      title: "Internet of things (iot)",
      detail:
        "Connecting devices and enabling intelligent data exchange to enhance productivity, efficiency, and automation across various sectors.",
    },
    {
      title: "Travel and transport",
      detail:
        "Enabling seamless travel experiences and optimizing logistics through tech-driven solutions for the transportation and hospitality industries.",
    },
    {
      title: "Non-for-profit",
      detail:
        "Providing tech services to support the digital transformation of nonprofit organizations, helping them reach their missions more effectively.",
    },
    {
      title: "Automative",
      detail:
        "Leveraging technology to drive innovation and efficiency in the automotive industry",
    },
  ];

  const { t } = useTranslation("translation", {
    keyPrefix: "Industries",
  });

  const [selected, setSelected] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isMobile = windowWidth <= 767;

  const cardButton = (index) => {
    return (
      <div
        className={
          (index == selected ? "active" : "inactive") + "   industriesSelctor"
        }
        onMouseEnter={() => setSelected(index)}
        onClick={() => setSelected(index)}
      >
        {" "}
        {t(`industriesCategory.${industries[index].title}.title`)}
      </div>
    );
  };
  const parallax = useParallax({
    speed: isMobile ? -1 :-5,
  });

  return (
    <>
      <div ref={parallax.ref} className="industriesMain">
        <div className="headingText">
          {" "}
          {t("Industries")}{" "}
          <img src={images.industriesUnderline} alt="underline-blue" />
        </div>
        <div className="industries-container">
          <div className="row firstRow">
            <div className="column">
              <div className="subTitle">
                <span className="subTitleText">
                  {t("Industries Sub Title")}{" "}
                </span>
                <span className="subTitleTextBold">
                  {t("Industries Sub Title Bold")}{" "}
                </span>
              </div>
            </div>
            <div className="column">
              {industries.map((item, index) => {
                if (index % 2 == 0) {
                  return (
                    <div key={index} className="contentRow ">
                      {index + 1 < industries.length && (
                        <div> {cardButton(index + 1)}</div>
                      )}
                      <div> {cardButton(index)} </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="row secondRow">
            <div className="column">
              <img
                src={images.IndustriesPageImage}
                alt="Product image "
                className="IndustriesPageImage"
              />
            </div>
            <div className="column subSection ">
              <div className="subSectionTitleHeading">
                {t(`industriesCategory.${industries[selected].title}.title`)}
                {/* {industries[selected].title} */}
              </div>
              <div className="subSectionDescription ">
                {/* {industries[selected].detail} */}
                {" "}
                {t(`industriesCategory.${industries[selected].title}.detail`)}
              </div>
            </div>
          </div>

          <div className="brandsRow">
            <div className="partnerBrands brandsRow">
              <div>
                <img className="brandsImg" src={images.scoopwhoopLogo} />
                <img className="brandsImg" src={images.housingLogo} />
                <img className="brandsImg" src={images.yayzyLogo} />
                <img className="brandsImg" src={images.koovsLogo} />
                <img className="brandsImg hideLogo" src={images.zellerLogo} />
                <img className="brandsImg hideLogo" src={images.mamaearthLogo} />
                <img className="brandsImg hideLogo" src={images.snapdealLogo} />
              </div>
              <div>
                <img className="brandsImg" src={images.scoopwhoopLogo} />
                <img className="brandsImg" src={images.housingLogo} />
                <img className="brandsImg" src={images.yayzyLogo} />
                <img className="brandsImg" src={images.koovsLogo} />
                <img className="brandsImg hideLogo" src={images.zellerLogo} />
                <img className="brandsImg hideLogo" src={images.mamaearthLogo} />
                <img className="brandsImg hideLogo" src={images.snapdealLogo} />
              </div>
              <div>
                <img className="brandsImg" src={images.scoopwhoopLogo} />
                <img className="brandsImg" src={images.housingLogo} />
                <img className="brandsImg" src={images.yayzyLogo} />
                <img className="brandsImg" src={images.koovsLogo} />
                <img className="brandsImg hideLogo" src={images.zellerLogo} />
                <img className="brandsImg hideLogo" src={images.mamaearthLogo} />
                <img className="brandsImg hideLogo" src={images.snapdealLogo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
