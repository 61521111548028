import React from "react";
import arrow from "../../images/contactUsArrow.png";
import { useTranslation } from "react-i18next";

const NavbarItem = ({ text }) => {

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="navbar-item">
        <a className="navbar-item-text">
        {t(text.transKey)}
        </a>
        <button type="button" className="navitem-button">
          <img src={arrow} alt="arrow button" draggable='false'/>
        </button>
      </div>
      <hr className="navbar-hor-line"/>
    </>
  );
};

export default NavbarItem;
