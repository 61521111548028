const industriesGe = {
  "Industries" : "Branchen" ,
  "Industries Sub Title": "Unsere technischen Dienstleistungen treiben Innovationen voran und bieten ",
  "Industries Sub Title Bold": "maßgeschneiderte Lösungen für verschiedene Bereiche.",
  "industriesCategory": {
    "Retail and e-commerce": {
      title: "Einzelhandel und E-Commerce",
      detail:
        "Wir unterstützen Unternehmen mit technischen Lösungen, um das Kundenerlebnis zu verbessern und Online-Abläufe zu optimieren. Wir haben technische Lösungen für das Ökosystem vernetzter Fahrzeuge und die gemeinsame Mobilität bereitgestellt.",
    },
    "Financial services": {
      title: "Finanzdienstleistungen",
      detail:
        "Bereitstellung modernster Technologie, um sichere Transaktionen zu ermöglichen, Prozesse zu optimieren und die Einhaltung gesetzlicher Vorschriften im Finanzsektor sicherzustellen.",
    },
    "Healthcare and life sciences": {
      title: "Gesundheitswesen und Biowissenschaften",
      detail: "Wir verändern die Gesundheitsversorgung und fördern die medizinische Forschung durch innovative technische Lösungen, um die Patientenversorgung und -ergebnisse zu verbessern.",
    },
    "Clean tech": {
      title: "Umweltfreundliche Technologie",
      detail:
        "Revolutionierung der Energieerzeugung, -einsparung und -nachhaltigkeit durch technologiegesteuerte Lösungen für eine umweltfreundlichere Zukunft.",
    },
    "Internet of things (iot)": {
      title: "Internet der Dinge (iot)",
      detail:"Verbinden Sie Geräte und ermöglichen Sie einen intelligenten Datenaustausch, um Produktivität, Effizienz und Automatisierung in verschiedenen Sektoren zu steigern.",
    },
    "Travel and transport": {
      title: "Reisen und Transport",
      detail:
        "Ermöglichung nahtloser Reiseerlebnisse und Optimierung der Logistik durch technologiegesteuerte Lösungen für die Transport- und Gastgewerbebranche.",
    },
    "Non-for-profit": {
      title: "Non-Profit",
      detail:
        "Bereitstellung technischer Dienstleistungen zur Unterstützung der digitalen Transformation gemeinnütziger Organisationen, damit diese ihre Ziele effektiver erreichen können.",
    },
    "Automative": {
      title: "Automobil",
      detail:
        "Nutzung von Technologie zur Förderung von Innovation und Effizienz in der Automobilindustrie",
    }
  }
}

export default industriesGe 
