const learnMoreGe = {
  "learnMore" : [
    " KI basierte Tools können die Entwickler bei der Entwicklung besserer Software unterstützen, indem sie die Code-generierung automatisieren, automatische Vervollständigung bereitstellen und Fehler erkennen.",
    " Beispiele für solche Tools sind OpenAI Codex, DeepCode und Diffblue. KI-Tools helfen auch bei der Codeanalyse, Testgenerierung und Fehlerüberwachung, wie in CodeGuru, Kite und Sentry zu sehen ist.",
    " Darüber hinaus nutzen Tools wie DeepTrace und SonarQube KI, um die Sicherheit und Codequalität zu verbessern. OverOps und GitSense nutzen KI, um Laufzeitdaten zu analysieren und Erkenntnisse für die Fehlerbehebung und Optimierung bereitzustellen.",
    " Insgesamt verbessern diese Tools die Produktivität, Codequalität und Fehlererkennung im gesamten Softwareentwicklungsprozess.",
  ],
  "title":"KI unterstützte digitale Transformationen."
}
  
export default learnMoreGe 
