import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from "./assets/locales/english/englishTranslation";
import germanTranslation from "./assets/locales/german/germanTranslation";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: englishTranslation
  },
  hi: {
    translation: {
      "Welcome to React": "प्रतिक्रिया में आपका स्वागत है",
      "Industries": "इंडस्ट्रीज",
      "Industries Sub Title":"हमारी तकनीकी सेवाएँ नवाचार को बढ़ावा देती हैं और प्रदान करती हैं ",
      "Industries Sub Title Bold": "विभिन्न डोमेन में अनुरूपित समाधान।",
      "industriesCategory": {
        "Retail and e-commerce": {
          title: "खुदरा और ई-कॉमर्स",
          detail:
            "ग्राहक अनुभव को बढ़ाने और ऑनलाइन संचालन को सुव्यवस्थित करने के लिए व्यवसायों को तकनीकी समाधानों के साथ सशक्त बनाना। हमने कनेक्टेड वाहन पारिस्थितिकी तंत्र और साझा गतिशीलता के लिए तकनीकी समाधान प्रदान किए हैं।",
        },
        "Financial services": {
          title: "वित्तीय सेवाएँ",
          detail:
            "सुरक्षित लेनदेन को सक्षम करने, प्रक्रियाओं को अनुकूलित करने और वित्तीय क्षेत्र में नियामक अनुपालन सुनिश्चित करने के लिए अत्याधुनिक तकनीक प्रदान करना।",
        },
        "Healthcare and life sciences": {
          title: "स्वास्थ्य देखभाल और जीवन विज्ञान",
          detail:
          "नवीन तकनीकी समाधानों के माध्यम से स्वास्थ्य देखभाल वितरण में बदलाव और चिकित्सा अनुसंधान को आगे बढ़ाना, रोगी देखभाल और परिणामों में सुधार करना।",
        },
        "Clean tech": {
          title:"स्वच्छ तकनीक",
          detail:
          "हरित भविष्य के लिए प्रौद्योगिकी-संचालित समाधानों के माध्यम से ऊर्जा उत्पादन, संरक्षण और स्थिरता में क्रांतिकारी बदलाव।",
        },
        "Internet of things (iot)": {
          title: "इंटरनेट ऑफ थिंग्स (आईओटी)", 
          detail:
          "विभिन्न क्षेत्रों में उत्पादकता, दक्षता और स्वचालन को बढ़ाने के लिए उपकरणों को कनेक्ट करना और बुद्धिमान डेटा विनिमय को सक्षम करना।",
        },
        "Travel and transport": {
          title: "यात्रा और परिवहन",
          detail:"परिवहन और आतिथ्य उद्योगों के लिए तकनीक-संचालित समाधानों के माध्यम से निर्बाध यात्रा अनुभवों को सक्षम करना और लॉजिस्टिक्स को अनुकूलित करना।",
        },
        "Non-for-profit": {
          title: "गैर-लाभकारी",
          detail:   "गैर-लाभकारी संगठनों के डिजिटल परिवर्तन का समर्थन करने के लिए तकनीकी सेवाएं प्रदान करना, उन्हें अपने मिशन तक अधिक प्रभावी ढंग से पहुंचने में मदद करना।" ,
        },
        "Automative": {
          title: "स्वचालित",
          detail: "ऑटोमोटिव उद्योग में नवाचार और दक्षता बढ़ाने के लिए प्रौद्योगिकी का लाभ उठाना",
        }
      },
    },
  },
  ge :{
    translation : germanTranslation
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
